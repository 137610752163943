import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import { locales } from './i18n/helper';
import Router from './router';

function App() {

  const { i18n } = useTranslation();

  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute("dir", i18n.language === locales.EN ? "ltr" : "rtl")
  }, [i18n.language])

  console.log = () => { }
  console.error = () => { }
  console.warn = () => { }

  return (
    <div className={`${i18n.language === locales.AR && "ar-font"}`}>
      <Router />
    </div>
  );
}

export default App;
