import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { routes } from "./helper";

const Home = lazy(() => import('../pages/Home'))
const DataModeller = lazy(() => import('../pages/DataModeller'))
const DataMarketplace = lazy(() => import('../pages/DataMarketplace'))
const MetadataInventory = lazy(() => import('../pages/MetadataInventory'))
const GeoSpatialBuilder = lazy(() => import('../pages/GeoSpatialBuilder'))
const OpenData = lazy(() => import('../pages/OpenData'))
const BusinessIntelligence = lazy(() => import('../pages/BusinessIntelligence'))
const AdvanceAnalytics = lazy(() => import('../pages/AdvanceAnalytics'))
const KnowledgeSharing = lazy(() => import('../pages/KnowledgeSharing'))
const GettingStarted = lazy(() => import('../pages/GettingStarted'))
const OurStory = lazy(() => import('../pages/OurStory'))


const Router = () => {
    return (
        <Routes>
            <Route path={routes.HOME} element={<Home />} />
            <Route path={routes.DATA_MODELLER} element={<DataModeller />} />
            <Route path={routes.DATA_MARKETPLACE} element={<DataMarketplace />} />
            <Route path={routes.METADATA_INVENTORY} element={<MetadataInventory />} />
            <Route path={routes.GEOSPATIAL_BUILDER} element={<GeoSpatialBuilder />} />
            <Route path={routes.OPEN_DATA} element={<OpenData />} />
            <Route path={routes.BUSINESS_INTELLIGENCE} element={<BusinessIntelligence />} />
            <Route path={routes.ADVANCE_ANALYTICS} element={<AdvanceAnalytics />} />
            <Route path={routes.KNOWLEDGE_SHARING} element={<KnowledgeSharing />} />
            <Route path={routes.GETTING_STARTED} element={<GettingStarted />} />
            <Route path={routes.OUR_STORY} element={<OurStory />} />
        </Routes>
    )
};

export default Router;