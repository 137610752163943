export const routes = {
  HOME: "/",
  DATA_MODELLER: "/data-modeller",
  DATA_MARKETPLACE: "/data-marketplace",
  METADATA_INVENTORY: "/metadata-inventory",
  GEOSPATIAL_BUILDER: "/geospatial-builder",
  OPEN_DATA: "/open-data",
  BUSINESS_INTELLIGENCE: "/business-intelligence",
  ADVANCE_ANALYTICS: "/advance-analytics",
  KNOWLEDGE_SHARING: "/knowledge-sharing",
  GETTING_STARTED: "/getting-started",
  OUR_STORY: "/our-story"
};

export const links = {
  instagram: "https://www.instagram.com/abudhabidigital/",
  twitter: "https://twitter.com/AbuDhabiDigital/",
  abudhabidata: "https://data.abudhabi/opendata",
  dmp: "https://dmp.data.abudhabi",
  dmplogin: "https://iam.taleem.ai/auth/realms/dmp/protocol/openid-connect/auth?client_id=dmp-portal&redirect_uri=https%3A%2F%2Fportal.taleem.ai%2F%23%2F&state=8b27c4c9-43e7-4cab-9850-7a6a4c8167a3&response_mode=fragment&response_type=code&scope=openid&nonce=5fa6dd28-28ae-4aa5-8ef8-9e83a483edd6"
}
